<template>
  <div id="app">
    <keep-alive>
    <router-view v-wechat-title="$route.meta.title"/>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted () {
    window.onload = function () {
      if (!window.sessionStorage.ISlogin) {
        // 关闭浏览器
        window.localStorage.removeItem('yun_user')
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('user_id')
        window.localStorage.removeItem('Authorization')
        window.localStorage.removeItem('ds_token')
        window.localStorage.removeItem('unit_name')
        window.localStorage.removeItem('unit_level')
        window.localStorage.removeItem('role_id')
        window.localStorage.removeItem('role_name')
        window.localStorage.removeItem('unit_id')
        window.location.reload()
      } else {
        // 刷新
      }
    }
    window.onunload = function () {
      window.sessionStorage.ISlogin = true
    }
    window.onbeforeunload = function () {
      window.sessionStorage.ISlogin = true
    }
  }
}
</script>
