export default {
  path: '/Robot_Management',
  component: () => import('@/views/Index_1'),
  name: 'Robot_Management',
  children: [
    {
      path: 'single_add_robot_yemian',
      component: () => import('@/views/Robot_Management/single_add_robot_yemian.vue'),
      name: 'single_add_robot_yemian',
      meta: { title: '国网小e软件机器人管控平台' }
    },
    {
      path: 'my_robot',
      component: () => import('@/views/Robot_Management/my_robot.vue'),
      name: 'my_robot',
      meta: { title: '机器人运行-国网小e软件机器人管控平台' }
    },
    {
      path: 'robot',
      component: () => import('@/views/Robot_Management/robot.vue'),
      name: 'robot',
      meta: { title: '机器人管理-国网小e软件机器人管控平台' }
    },
    {
      path: 'query_robot',
      component: () => import('@/views/Robot_Management/query_robot.vue'),
      name: 'query_robot',
      meta: { title: '机器人类别-国网小e软件机器人管控平台' }
    },
    {
      path: 'case',
      component: () => import('@/views/Robot_Management/case.vue'),
      name: 'case',
      meta: { title: '案例展示-国网小e软件机器人管控平台' }
    },
    {
      path: 'units',
      component: () => import('@/views/units/units.vue'),
      name: 'units',
      meta: { title: '单位管理-国网小e软件机器人管控平台' }
    },
    {
      path: 'unitsapply',
      component: () => import('@/views/unitsapply/unitsapply.vue'),
      name: 'unitsapply',
      meta: { title: '单位绑定申请-国网小e软件机器人管控平台' }
    },
    {
      path: 'unitsapproval',
      component: () => import('@/views/unitsapproval/unitsapproval.vue'),
      name: 'unitsapproval',
      meta: { title: '单位绑定审核-国网小e软件机器人管控平台' }
    },
    {
      path: 'users',
      component: () => import('@/views/users/users.vue'),
      name: 'users',
      meta: { title: '用户管理-国网小e软件机器人管控平台' }
    },
    {
      path: 'usersapply',
      component: () => import('@/views/users/usersapply.vue'),
      name: 'usersapply',
      meta: { title: '用户绑定申请-国网小e软件机器人管控平台' }
    },
    {
      path: 'usersapproval',
      component: () => import('@/views/users/usersapproval.vue'),
      name: 'usersapproval',
      meta: { title: '用户绑定审核-国网小e软件机器人管控平台' }
    },
    {
      path: 'welcome',
      component: () => import('@/views/welcome/welcome'),
      name: 'welcome',
      meta: { title: '国网小e软件机器人管控平台' }
    },
    {
      path: 'loginlog',
      component: () => import('@/views/log/loginlog'),
      name: 'loginlog',
      meta: { title: '登录日志-国网小e软件机器人管控平台' }
    },
    {
      path: 'supervisory',
      component: () => import('@/views/supervisory/supervisory'),
      name: 'supervisory',
      meta: { title: '大屏监控-国网小e软件机器人管控平台' }
    },
    {
      path: 'designer',
      component: () => import('@/views/designer/designer'),
      name: 'designer',
      meta: { title: '国网小e软件机器人管控平台' }
    },
    {
      path: 'designerapply',
      component: () => import('@/views/designer/designerapply'),
      name: 'designerapply',
      meta: { title: '国网小e软件机器人管控平台' }
    },
    {
      path: 'designerapproval',
      component: () => import('@/views/designer/designerapproval'),
      name: 'designerapproval',
      meta: { title: '国网小e软件机器人管控平台' }
    },
    {
      path: 'ai',
      component: () => import('@/views/ai/ai'),
      name: 'ai',
      meta: { title: '国网小e软件机器人管控平台' }
    }

  ]
}
