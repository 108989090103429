import Vue from 'vue'
import VueRouter from 'vue-router'
import logins from '@/router/logins'
import RoBotManageRouter from './robot_manage'
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  RoBotManageRouter,
  logins,
  {
    path: '/*',
    redirect: '/logins'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('ds_token')
  if (to.path === '/logins') {
    window.localStorage.removeItem('yun_user')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('user_id')
    window.localStorage.removeItem('Authorization')
    window.localStorage.removeItem('unit_name')
    window.localStorage.removeItem('unit_id')
    window.localStorage.removeItem('unit_level')
    window.localStorage.removeItem('role_id')
    window.localStorage.removeItem('role_name')
    // window.localStorage.removeItem('ds_token')
    next()
  } else {
    if (token === null || token === undefined || token === '') {
      Message.error('Token验证失败，请重新登录！')
      next('/logins')
      window.localStorage.removeItem('yun_user')
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('user_id')
      window.localStorage.removeItem('Authorization')
      window.localStorage.removeItem('unit_name')
      window.localStorage.removeItem('unit_id')
      window.localStorage.removeItem('unit_level')
      window.localStorage.removeItem('role_id')
      window.localStorage.removeItem('role_name')
    } else {
      next()
    }
  }
})

export default router
